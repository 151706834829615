import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  computed,
  effect,
  inject,
  input,
  signal,
} from '@angular/core';
import { Address, MongoAddress, isMongoAddress, isPostgresAddress, mongoAddressProxyHandler, stateList } from '@mca/shared/domain';
import { ReferencesService } from '@mca/references/api';
import { catchError, distinctUntilChanged, EMPTY, filter, switchMap } from 'rxjs';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { UppercaseDirective } from '@mca/shared/ui';
import { InputTextModule } from 'primeng/inputtext';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'lib-shared-smart-ui-address',
  templateUrl: './address.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, InputTextModule, UppercaseDirective, DropdownModule],
})
export class AddressComponent implements OnInit {
  private refService = inject(ReferencesService);
  private fb = inject(FormBuilder);
  private destroyRef = inject(DestroyRef);

  // keep as emitter for compatibility with dynamic form outputs
  @Output() addressChange = new EventEmitter<MongoAddress | Address>();
  @Input() set address(address: MongoAddress | Address | undefined) {
    this.addressSignal.set(address);
  }

  private addressSignal = signal<MongoAddress | Address | undefined>(undefined);
  private addressProxyValue = computed<MongoAddress & Address>(() => {
    const address = this.addressSignal();
    if (!address) {
      return new Proxy(new MongoAddress(), mongoAddressProxyHandler);
    }
    if (isPostgresAddress(address)) {
      return address;
    }
    return new Proxy(address, mongoAddressProxyHandler);
  });
  private addressProxyValue$ = toObservable(this.addressProxyValue);
  disabled = input<boolean>(false);
  shortView = input<boolean>(false);

  states = [{ label: '', value: 0 }, ...stateList.map(item => ({ label: item, value: item }))];

  form = this.fb.nonNullable.group({
    street_line1: '',
    street_line2: '',
    city: '',
    state: '',
    zip: '',
    county: '',
    country: 'US',
  });

  constructor() {
    effect(() => {
      if (this.shortView()) {
        this.form.disable({ emitEvent: false });
        return;
      }
      if (this.disabled()) {
        this.form.disable({ emitEvent: false });
      } else {
        this.form.enable({ emitEvent: false });
      }
    });
  }

  ngOnInit() {
    this.watchZipChanges();
    this.syncFormAndProxyValue();
  }

  private syncFormAndProxyValue() {
    this.addressProxyValue$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(proxyAddress => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { streetLine1, streetLine2, ...address } = proxyAddress;
      const newValue = { ...address, street_line1: proxyAddress.street_line1, street_line2: proxyAddress.street_line2 };
      const valueChanged = Object.keys(this.form.value).some(
        key => this.form.value[key as keyof typeof this.form.value] !== newValue[key as keyof typeof newValue],
      );
      if (valueChanged) {
        this.form.patchValue(newValue);
      }
    });
    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(formValue => {
      const proxyAddress = Object.assign(this.addressProxyValue(), formValue);
      const value = this.proxyToAddress(proxyAddress);
      this.addressChange.emit(value);
    });
  }

  private proxyToAddress(proxyAddress: Address | MongoAddress) {
    const address = isMongoAddress(this.addressSignal() as Address) ? new MongoAddress() : new Address();
    for (const key of Object.keys(address)) {
      address[key as keyof (Address | MongoAddress)] = proxyAddress[key as keyof (Address | MongoAddress)];
    }
    return address;
  }

  private watchZipChanges() {
    this.form.controls.zip.valueChanges
      .pipe(
        distinctUntilChanged(),
        filter(zip => zip?.toString().length === 5),
        switchMap(zip => this.refService.getReferenceRecord('zipcodes', zip).pipe(catchError(() => EMPTY))),
        filter(zipData => !!Object.values(zipData).length),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(response => {
        this.form.patchValue({
          city: response.city,
          state: response.state,
          county: response.county,
        });
      });
  }
}
