import { FormGroup, Validators } from '@angular/forms';
import { MongoAddress } from '@mca/shared/domain';
import { FieldConfig } from '@mca/shared/feature-dynamic-form';
import { enumOfStringsToOptionsReversed, enumToOptions } from '@mca/shared/util';
import {
  AccountReceivableCollectabilityRank,
  AccountReceivableCollectabilityRankDescriptions,
  AccountReceivableCollectabilityRankLabels,
  AccountReceivableDistributionCriteria,
  AccountReceivableDistributionCriteriaLabels,
  AccountReceivableStatus,
} from '@mca/user/domain';
import { AddressComponent } from '../address/address.component';

export const accountReceivableAssignFields: FieldConfig[] = [
  {
    name: 'status',
    label: 'Status',
    type: 'select',
    options: {
      items: enumToOptions(AccountReceivableStatus),
      bindLabel: 'label',
      bindValue: 'value',
    },
    value: null,
    required: true,
    validation: [Validators.required],
  },
  {
    name: 'amount',
    label: 'Amount',
    inputType: 'number',
    value: 0,
  },
  {
    type: 'textarea',
    name: 'note',
    label: 'Note',
    value: '',
  },
];

export const accountReceivableUserFields: FieldConfig[] = [
  {
    name: 'firstname',
    label: 'First Name',
    class: 'col-6',
    value: '',
    required: true,
    validation: [Validators.required],
  },
  {
    name: 'lastname',
    label: 'Last Name',
    class: 'col-6',
    value: '',
  },
  {
    name: 'fullname',
    label: 'Company Name',
    class: 'col-6',
    value: '',
    required: true,
    validation: [Validators.required],
  },
  {
    name: 'appear_as',
    label: 'Appear As',
    class: 'col-6',
    type: 'multiInput',
    value: [],
  },
  {
    name: 'cellphone',
    label: 'Phone Number',
    class: 'col-3',
    value: '',
  },
  {
    name: 'emails',
    label: 'Emails',
    class: 'col-9',
    value: '',
  },
  {
    name: 'website',
    label: 'Website',
    class: 'col-6',
    value: '',
  },
  {
    name: 'address',
    type: 'component',
    component: AddressComponent,
    componentOutputs: {
      addressChange: (address: MongoAddress, form: FormGroup) => {
        form.patchValue({ address }, { emitEvent: false });
      },
    },
  },
  {
    name: 'industry',
    label: 'Industries',
    class: 'col-6',
    type: 'multiselect',
    options: {
      items: [],
    },
    value: [],
  },
  {
    name: 'collectability_rank',
    label: 'Collectability Ranking',
    class: 'col-6',
    type: 'select',
    options: {
      items: enumOfStringsToOptionsReversed(AccountReceivableCollectabilityRankLabels).map(item => ({
        ...item,
        tooltip: AccountReceivableCollectabilityRankDescriptions[item.value as AccountReceivableCollectabilityRank],
      })),
      bindLabel: 'label',
      bindValue: 'value',
    },
    value: null,
  },
  {
    name: 'distribution_criteria',
    label: 'Distribution Criteria ',
    class: 'col-6',
    type: 'multiselect',
    options: {
      items: enumOfStringsToOptionsReversed(AccountReceivableDistributionCriteriaLabels),
      bindLabel: 'label',
      bindValue: 'value',
    },
    value: [],
    dependsOn: (() => {
      let lastRankValue: AccountReceivableCollectabilityRank | null = null;
      return (form, fieldConfig) => {
        if (form.value.collectability_rank === lastRankValue) {
          return fieldConfig;
        }
        lastRankValue = form.value.collectability_rank;
        const filterCondition = (value: AccountReceivableDistributionCriteria) =>
          value !== 'tbd' || (form.value.collectability_rank as AccountReceivableCollectabilityRank) === 'tbd';
        form.controls.distribution_criteria.setValue(form.value.distribution_criteria?.filter(filterCondition), { emitEvent: false });
        return {
          ...fieldConfig,
          options: Object.assign(fieldConfig.options ?? {}, {
            items: enumOfStringsToOptionsReversed(AccountReceivableDistributionCriteriaLabels).filter(option =>
              filterCondition(option.value as AccountReceivableDistributionCriteria),
            ),
          }),
        };
      };
    })(),
  },
  {
    name: 'assign',
    label: 'Assign on deal',
    class: 'col-11 d-flex justify-content-end',
    type: 'checkbox',
    value: true,
  },
  {
    name: 'is_active',
    label: 'Active',
    class: 'col-1 d-flex justify-content-end',
    type: 'checkbox',
    value: true,
  },
];
